@media screen and (max-width: 1300px) {

    /* BIO */ 
   
    .trigger .textbio .inside-bio{
        grid-template-columns: 100%;
    }
    .trigger .textbio .inside-bio .item{
        margin-left: 0;
    }

}
@media screen and (max-width: 1250px) {
    /* CALENDAR */ 
    .calendar .first-view .view-more{
        padding: 10px 0px;
        width: 100%;
        margin-top: 20px;
    }

}

@media screen and (max-width: 1200px) {

    /* CONTACT */ 
    .contact-grid{
        height: 1350px;
    }
    .contact-grid .item:nth-child(1){
        margin-left: 7.5vw;
       position: relative;
       top: 40vh;
       padding-bottom: 0;
    }

    /* CALENDAR */ 
    .item-calendar {
        width: 65vw;
    }
    /* CALENDAR */ 
    .calendar{
    	background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ,linear-gradient(360deg, #000000 2.11%, rgba(0, 0, 0, 0) 19.48%), linear-gradient(260.45deg, #000000 23.1%, rgba(0, 0, 0, 0) 54.33%), url("../assets/calendar-2-2-web.jpg"), black;
        background-size: cover;
    }
    
     .calendar .calendar-grid{
         float: left;
         margin-left: 7.5vw;
         width: 85vw;
     }
     .calendar .first-view{
         width: 85vw;
     }
     .item-calendar{
         margin-left: 0;
         width: 85vw;
     }
     .calendar .first-view{
         flex-wrap: wrap;
     }

    /* MEDIA */ 
    .media-bg .media-content .youtube-section iframe{
        min-height: 600px;
    }

    /* START */ 
    #logo {
        width: 85vw;
    }
        /* Calendar Section */
        .calendar .calendar-grid  .modal-content {
            width: 80vw;
            margin: 25% auto;
        }

}

@media screen and (max-width: 1080px) {
/* START */ 
.start, .calendar, .contact-grid{
    margin-top: 95px; 

}
.start .featured-section{
    width: 50vw;
}
/* MEDIA */ 
#arrowR, #arrowL{
    display: block;
}
.navbar-media .bottom-bar{
    &::after{
        right: 0;
        background: linear-gradient(90deg,rgba(247, 247, 247, 0) 0, #DDDDDD 70.5%, #DDDDDD);
        z-index: 489489;
        content: '';
width: 150px;
height: 100%;
position: absolute;
top: 0;
bottom: 0;
pointer-events: none;
-webkit-transition: opacity 200ms ease;
transition: opacity 200ms ease;
}

&::before{
left: 0;
background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0, #DDDDDD 99.5%, #DDDDDD);
content: '';
z-index: 3342424;
width: 150px;
height: 100%;
position: absolute;
top: 0;
bottom: 0;
pointer-events: none;
-webkit-transition: opacity 200ms ease;
transition: opacity 200ms ease;
      }
}
 /* Nav Active */
 .nav-active {
    -webkit-transform: translateX(0%) !important;
    transform: translateX(0%) !important;
    transition: transform .5s ease-in !important;
    position: fixed !important;
}
.burger-align .nav-active {
    position: relative !important;
}
.nav-active.navbar{
    top: 0 !important;
}
.navbar {
    z-index: 900;
    .nav-content{
        position: relative;
        .logo-mobile{
            display: inline-block;
            position: relative;
            z-index: 910;
            margin-left: 7.5vw;
            a{
                text-decoration: none;
                color: black;
                p{
                    margin-top: -2px;
                }
            }
        }
        .logo-desktop{ 
            display: none;
        }
    }
    .nav-links {
        position: fixed;
        margin-left: 0px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        background-color: white;
        flex-direction: column;
        align-items: center;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transition: transform 0.5s ease-in;
        -webkit-transition: transform 0.5s ease-in;
        color: black;

        .normal-link{
            opacity: 0;
            margin-top: 20px;
            position: relative !important;
        }

        &.nav-active .normal-link {
            animation: navLinkFade .6s ease forwards;
            animation-delay: .5s;
        }
        &.nav-active .normal-link:nth-child(2) {
            animation-delay: .7s;
        }
        &.nav-active .normal-link:nth-child(3) {
            animation-delay: .9s;
        }
        &.nav-active .normal-link:nth-child(4) {
            animation-delay: 1s;
        }
        &.nav-active .normal-link:nth-child(5) {
            animation-delay: 1.2s;
        }
        &.nav-active .normal-link:nth-child(6) {
            animation-delay: 1.4s;
        }
        &.nav-active .normal-link:nth-child(7) {
            animation-delay: 1.6s;
        }
        &.nav-active .normal-link:nth-child(8) {
            animation-delay: 1.8s;
        }
    }
    .logo {
        svg {
            display: inline-block;
            width: 40px;
            height: auto;
        }
        h1 {
            font-size: 28px;
        }
    }
    .nav-margin {
        margin-top: -70vh;
    }
    .burger {
        display: block !important;
    }
    .language-switch-desktop{
        display: none;
    }
	.nav-content .language-switch-mobile{
        display: block;
        }
    .nav-active.burger{
        transform: translateY(-50%) !important;
    }
    
}
}
@media screen and (max-width: 950px) {
    /* START */ 
    .start{ 
        background-size: 180% !important;
        background: linear-gradient(266.16deg, rgba(221, 221, 221, 0.9) 0.50%, rgba(240, 240, 240, 0) 86.63%),  url('../assets/hero-2-3-web.jpg'), #F0F0F0;
        
        background-position-x: 20% !important;
        background-position-y: center !important;
    }
    .start .featured-section{
       // float: left;
        margin-left: 7.5vw;
        width: 40vw;
    }
    /* BIO */ 
    .item-press .press-grid{
        grid-template-columns: repeat(3, 1fr);
    }


    /* MEDIA */ 
    .media-bg .media-content .soundcloud-section{
        grid-template-columns: repeat(1, 1fr);
    }
    .media-bg .media-content .youtube-section iframe{
        min-height: 400px;
    }
    .media-bg .media-content .media-grid-2{
        grid-template-columns: repeat(1, 1fr);
    }
    .media-bg .media-content ul{
        flex-wrap: wrap;
        li{
            margin-top: 10px;
        }
    }
    .media-bg .media-content .impressionen-section img{
        width: 85vw;
    }
    .media-bg .media-content .impressionen-section .container-arrow{
        width: 85vw;
    }
    /* Nav Active */
    .nav-active {
        -webkit-transform: translateX(0%) !important;
        transform: translateX(0%) !important;
        transition: transform .5s ease-in !important;
        position: fixed !important;
    }
    .burger-align .nav-active {
        position: relative !important;
    }
    .nav-active.navbar{
        top: 0 !important;
    }
    .navbar {
        z-index: 900;
        .nav-content{
            position: relative;
            .logo-mobile{
                display: inline-block;
                position: relative;
                z-index: 910;
                margin-left: 7.5vw;
                a{
                    text-decoration: none;
                    color: black;
                    p{
                        margin-top: -2px;
                    }
                }
            }
            .logo-desktop{ 
                display: none;
            }
        }
        .nav-links {
            position: fixed;
            margin-left: 0px;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            display: flex;
            justify-content: center;
            background-color: white;
            flex-direction: column;
            align-items: center;
            transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transition: transform 0.5s ease-in;
            -webkit-transition: transform 0.5s ease-in;
            color: black;

            .normal-link{
                opacity: 0;
                margin-top: 20px;
                position: relative !important;
            }

            &.nav-active .normal-link {
                animation: navLinkFade .6s ease forwards;
                animation-delay: .5s;
            }
            &.nav-active .normal-link:nth-child(2) {
                animation-delay: .7s;
            }
            &.nav-active .normal-link:nth-child(3) {
                animation-delay: .9s;
            }
            &.nav-active .normal-link:nth-child(4) {
                animation-delay: 1s;
            }
            &.nav-active .normal-link:nth-child(5) {
                animation-delay: 1.2s;
            }
            &.nav-active .normal-link:nth-child(6) {
                animation-delay: 1.4s;
            }
            &.nav-active .normal-link:nth-child(7) {
                animation-delay: 1.6s;
            }
            &.nav-active .normal-link:nth-child(8) {
                animation-delay: 1.8s;
            }
        }
        .logo {
            svg {
                display: inline-block;
                width: 40px;
                height: auto;
            }
            h1 {
                font-size: 28px;
            }
        }
        .nav-margin {
            margin-top: -70vh;
        }
        .burger {
            display: block !important;
        }
        .nav-active.burger{
            transform: translateY(-50%) !important;
        }
        
    }

    
}
@media screen and (max-width: 888px) {
    /* MEDIA */ 
    .media-bg .media-content .social-media-content .social-link-section a svg{
        height: 50px;
    }
}
@media screen and (max-width: 777px) {
    /* FOOTER */ 
    footer {
        .footer-section {
            padding-bottom: 27px;
            .item {
                margin-top: 7px;
            }
            .item-3 {
                text-align: left;
            }
        }
    }
    .calendar .calendar-grid .item .modal-content {
        h1 {
            font-size: 35px;
        }
        .sub {
            font-size: 15px;
        }
        .information-2 {
            font-size: 17px;
        }
    }
}
@media screen and (max-width: 700px) {
     /* BIO */ 
     .item-press .press-grid{
        grid-template-columns: repeat(1, 1fr);
    }
    .calendar{
        background: linear-gradient(360deg, #000000 2.11%, rgba(0, 0, 0, 0) 19.48%), linear-gradient(107.11deg, rgba(0, 0, 0, 0.65) 17.1%, rgba(0, 0, 0, 0) 143.01%), url("../assets/calendar-mobile-2-web.jpg"), black; 
        background-size: 100%;
        background-repeat: no-repeat;   
 
     }
    /* START */ 
    .start{
        background: url(../assets/start-mobile-2-web.jpg), #F0F0F0;
        background-size: 100% !important;
        background-position: center -190px !important;
        min-height: 1800px;
    }
     .start .featured-section{
        margin-left: 7.5vw;
        float: left;
        width: 85vw;
        margin-top: 95vh;
        grid-template-columns: 50% 50%, 100%;
        .iframes{
            grid-template-columns: repeat(2, 1fr);
        } 
    }
    .start .featured-section .iframe-setion iframe{
        min-height: 180px;
    }
    /* BIO */
    .animation-bi{
        background: url('../assets/media-mobile-2-web.jpg');
        background-position-x: center;
        background-size: 100%;
        background-repeat: no-repeat;

    }
    .trigger .textbio .inside-bio .item p{
        column-count: 1;
    }
    /* CALENDAR */ 
    .calendar .first-view{ 
        padding-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        .event-information{
            text-align: right;
            width: 50%;
        }
        .view-more{
        width: 100%;
        margin-top: 20px;
        padding: 10px 0px;
    }
}
/* MEDIA */ 
.navbar-media .bottom-bar ul li:nth-child(1){
    margin-left: 30px;
} 
.navbar-media .bottom-bar ul li:last-child{
    margin-right: 120px;
}
/* CONTACT */ 
    .contact-grid{
        background-position-x: 8%;
        background: url('../assets/contact-2-mobile-web.jpg'), black;
	    background-size: 100%;
        background-repeat: no-repeat;
        height: 1500px;
    }
    .contact-grid .item:nth-child(1){
        top: 40vh;
    }
    .managment{
        width: 85vw;
    }
    .contact-grid h2{
        font-size: 62px; 
    }
    .contact-grid .item .contact-item .hover-box .center-link a{
        font-size: 18px;
        margin-left: 45px;
    }
    .contact-grid .item .contact-item svg{
        width: 28px;
    }
    .contact-grid .item .contact-item{
        margin-top: 10px
    }

    /* MEDIA */ 
    .media-bg .media-content .impressionen-section p{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .media-bg .media-content .youtube-section a{
        display: flex;
        justify-content: center;
    }
    

}
@media screen and (max-width: 517px) {
    /* FOOTER */
    footer .footer-section {
        .item {
            text-align: center
        }
    }
    
    /* MEDIA */ 
    .media-bg .media-content h2{
        font-size: 52px;
    }
    .media-bg .media-content .youtube-section iframe{

            height: 240px !important;
            min-height: 240px !important;
    }
    .media-bg .media-content .social-media-content .social-link-section a:nth-child(1) img, .media-bg .media-content .social-media-content .social-link-section a img{
        width: auto;
        height: 50px;
        margin: 20px
    }
    .media-bg .media-content .media-grid-2 .item:nth-child(2) .cover-grid{
        grid-template-columns: auto;
    }
   
}

@media screen and (max-width: 500px) {
    /* START */
    .start .featured-section{
        margin-top: 95vh;
    }
       /* START */ 
       .start{
        min-height: 1650px;
        background-position: center 0px !important;

       }
    
    .start .featured-section .iframe-section iframe{
        height: 180px;
        min-height: 180px;
    }
    /* MEDIA */ 
    .media-bg .media-content ul li{
        width: 100%;
    }
    .navbar-media .bottom-bar::after{
        background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0, #DDDDDD 99.3%, #DDDDDD);
    }
    .navbar-media .bottom-bar::before{

    background: linear-gradient(270deg, rgba(247, 247, 247, 0) 0, #DDDDDD 99.9%, #DDDDDD);
    }

    /* INTRO */ 
    .intro .intro-text h1{
        font-size: 55px ;
    }
    .intro .intro-text h1:nth-child(2){
        font-size: 23px;
    }

    /* CALENDAR */ 
    .calendar .first-view h3{
        width: 100%;
    }
    .calendar .first-view .event-information{
        text-align: left;
        width: 80%;
    }
}
@media screen and (max-width: 350px) {

    /* CALENDAR */
    .calendar .first-view h3{
        width: auto;
    }
    .contact-grid .item .contact-item .hover-box .center-link a{
        font-size: 16px;
    }

    /* NAV */ 
    .navbar .nav-content .nav-links{
        padding-top: 45px;
    }

    /* INTRO */
    .intro .intro-text h1{
        font-size: 45px ;
    }
    .intro .intro-text h1:nth-child(2){
        font-size: 18px;
    }

    /* BIO */ 
    .item-press h2{
        font-size: 52px;
    }
    /* START */ 
    .start .featured-section .first-view h4{
        font-size: 15px;
    }
    .start .featured-section .first-view h3{
        font-size: 20px;
    }
}
@media screen and (max-width: 300px) {

    /* CALENDAR */

    .contact-grid .item .contact-item .hover-box .center-link a{
        font-size: 13px;
    }

}
