.contact-grid {
	margin-top: 0;
	// display: grid;
	// grid-template-columns: repeat(2, 1fr);
	background: linear-gradient(264.23deg, #000000 20.85%, rgba(0, 0, 0, 0) 40.77%),
		url('../assets/contact-2-web.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
	height: 100vh;
	min-height: 950px;
	margin-top: 120px;
	h2 {
		margin-top: 0;
		font-size: 72px;
		font-weight: 600;
	}
	.item:nth-child(1) {
		color: white;
		margin-left: 55vw; 
		// margin-top: 40px;
	}
	.item {
		padding-top: 15vh;
		padding-bottom: 0px;
		.contact-item {
			margin-top: 20px;
			svg {
				width: 35px;
				color: white; 
			}
			.hover-box {
				position: relative;
				svg {
					opacity: 1;
					color: white;
				}
				.center-link {
					a {
						width: auto;
						font-style: normal;
						font-weight: normal;
						font-size: 26px;
						opacity: 1;
						text-decoration: none;
						color: white;
						transition: .3s;
						margin-left: 60px;
						position: absolute;
						transform: translateY(-50%);
						top: 50%;
						margin-top: -2.5px;

						&:hover {
							opacity: .7;
						}
					}
				}
			}
		}
	}
}

.managment{
	text-align: left;
	width: 50%;
	h3{
		font-size: 35px;
		margin-bottom: 10px;
		margin-top: 20px;
	}
	img{
		width: 150px;
	}
	a{
		color: white;
		text-decoration: none;
		border-bottom: 1px solid white;
	}
	b{
		font-size: 120%;
	}
	.img-link{
		border-bottom: none;
	}
}

.play-section{
	display: flex;
	align-items: center;
	p{
		margin-right: 20px;
		font-weight: bold;
		font-size: 20px;
	}
}
.tel-link{
	position: relative;
	top: 5px;
}