.imprint-section{
    width: 85vw;
    margin: 0 auto;
    margin-top: 20vh;
    margin-bottom: 15vh;
    h2{
        margin-bottom: 20px;
        margin-top: 24px;
        word-wrap: break-word;
        &:nth-child(1){
            font{
            font-size: 40px !important;
        }
        }
    }
    font, p{
        color: black !important;
    }
    font{
        margin-bottom: 10px;
    }
    b{
        margin-top: 20px;
        display: block;
    }
}