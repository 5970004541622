
.trans-start{
	width: 100vw;
	margin-top: -100vh;
}
.start {
	background: url('../assets/hero-2-3-web.jpg'), #F0F0F0;
	height: calc(100vh - 120px);
	min-height: 600px;
	background-size: cover;
	background-repeat: no-repeat;
    background-position: center;
	padding-bottom: 60px;
    margin-top: 125px;
    background-repeat: no-repeat !important;

    
    
	.featured-section{
		float: right;
		margin-right: 7.5vw;
		margin-top: 9vh;
        width: 30vw;
        color: black;
        .headline-start-calendar{
            font-size: 40px;
        }
        a{
            text-decoration: none;
        }
        .item-calendar-start {
            margin-bottom: 70px;

        }
        .first-view {
            color: black;
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            width: 100%;
            position: relative;
            font-family: 'Open Sans';
    
            padding-bottom: 10px;
            padding-top: 10px;
            h3 {
                font-size: 24px;
                font-weight: bold;
            font-family: 'Open Sans';
            margin-right: 10px;
    
            }
    
            h4 {
                font-size: 18px;
                font-weight: bold;
            font-family: 'Open Sans';
            text-align: right;
            
    
            }
    
            h5 {
                font-size: 12px;
                font-weight: normal;
                margin-top: 3px;
            font-family: 'Open Sans';
            text-align: right;
    
            }
    
            .view-more {
                border: 2px solid white;
                font-weight: 400;
                color: white;
                float: right;
                outline: none;
                background: transparent;
                padding: 10px 0;
                width: 100%;
                text-decoration: none;
                font-weight: bold;
                font-size: 18px;
                transition: .3s ease;
                cursor: pointer;
                font-size: 12px;
                font-family: 'Open Sans';
                font-weight: 600;
    
                &:hover {
                    background: white;
                    color: black;
                }
            }
        }
        .item-calendar .first-view:last-child{
            border-bottom: none;
            }

		.social-links-start{
            margin-top: 45px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            text-align: center;
            a{
                display: flex;
                align-items: center;
                height: 40px;

                svg{
                    height: 40px;
                    margin: 0 auto;
                    text-align: center;
                    width: 100%;
    
                }
            }
               
        }
	}
}



/* 
.fade-out{
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: white;

	z-index: 9999999;

	img{
		width: 100%;
		height: 100vh;
	}
	.fade-out-image{
		width: 100vw;
		background: url('../assets/fade-out.jpg'), white;
		height: 100vh;
		background-size: cover;
		z-index: 99999;
		background-position-x: center;

	}
}
*/



.intro-content{
    z-index: 911;
}
.landing {
    height: 100vh;
    background: white;
    padding: 0rem 5rem;
    z-index: 911;

}

.intro {
    background: white;
    position: fixed;
    z-index: 9111;

    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .intro-text {
        text-align: center;
        h1 {
            &:nth-child(1){
                font-family: 'solano-gothic-pro-mvb', sans-serif;
                margin-bottom: 13px;
            }
            &:nth-child(2){
                font-family: 'Open Sans';
                font-size: 30px;
                display: inline-block;
                span{
                border-top: 1px solid rgba(0, 0, 0, .3);
                padding: 6px 40px;
                color: black;
                }
                margin: 0 auto;
            }
            font-weight: 900;
            text-align: center;
            font-size: 5rem;
            
        }
    }
}

.slider {
    background: linear-gradient(184.54deg, #A1C4FD 20.32%, #C2E9FB 179.81%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translateY(100%);
    z-index: 99999999999999;
    visibility: hidden;
}

.hide {
    background: white;
    overflow: hidden;
    .text {
        transform: translateY(100%);
        display: inline-block;
    }
}

#logo{
	animation: fill 1s ease forwards 2.9s;
	display: block;
    width: 60vw;
    height: auto;
    margin-bottom: 20px;
}
#logo path:nth-child(1){
    stroke-dasharray: 756.7575073242188;
    stroke-dashoffset: 756.7575073242188;
    animation: line-anim 2s ease forwards 1s;
}
#logo path:nth-child(2){
    stroke-dasharray: 540.8999633789062;
    stroke-dashoffset: 540.8999633789062;
    animation: line-anim 2s ease forwards 1.1s;

}
#logo path:nth-child(3){
    stroke-dasharray: 827.9262084960938;
    stroke-dashoffset: 827.9262084960938;
    animation: line-anim 2s ease forwards 1.2s;

}
#logo path:nth-child(4){
    stroke-dasharray: 303.6960144042969;
    stroke-dashoffset: 303.6960144042969;
    animation: line-anim 2s ease forwards 1.3s;

}
#logo path:nth-child(5){
    stroke-dasharray: 537.2930297851562;
    stroke-dashoffset: 537.2930297851562;
    animation: line-anim 2s ease forwards 0.8s;

}
#logo path:nth-child(6){
    stroke-dasharray: 303.6960144042969;
    stroke-dashoffset: 303.6960144042969;
    animation: line-anim 2s ease forwards 0.9s;

}
#logo path:nth-child(7){
    stroke-dasharray: 623.46875;
    stroke-dashoffset: 623.46875;
    animation: line-anim 2s ease forwards 1s;

}
#logo path:nth-child(8){
    stroke-dasharray: 1242.220458984375;
    stroke-dashoffset: 1242.220458984375;
    animation: line-anim 2s ease forwards 1.1s;

}
#logo path:nth-child(9){
    stroke-dasharray: 584.1041259765625;
    stroke-dashoffset: 584.1041259765625;
    animation: line-anim 2s ease forwards 1.2s;

}
#logo path:nth-child(10){
    stroke-dasharray: 833.5394897460938;
    stroke-dashoffset: 833.5394897460938;
    animation: line-anim 2s ease forwards 1.4s;

}
#logo path:nth-child(11){
    stroke-dasharray: 537.2935791015625;
    stroke-dashoffset: 537.2935791015625;
    animation: line-anim 2s ease forwards 1.7s;

}
#logo path:nth-child(12){
    stroke-dasharray: 576.6311645507812;
    stroke-dashoffset: 576.6311645507812;
    animation: line-anim 2s ease forwards 1.9s;

}
#logo path:nth-child(13){
    stroke-dasharray: 301.0957946777344;
    stroke-dashoffset: 301.0957946777344;
    animation: line-anim 2s ease forwards 2.1s;

}


@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fill{
    from{
        fill: transparent;
    }
    to{
        fill: black;
    }
}

/*

#logo{
	animation: fill .5s ease forwards 2.9s;
	display: block;
	width: 85vw;
}
#logo path:nth-child(1){
    stroke-dasharray: 756.7575073242188;
    stroke-dashoffset: 756.7575073242188;
    animation: line-anim 2s ease forwards;
}
#logo path:nth-child(2){
    stroke-dasharray: 540.8999633789062;
    stroke-dashoffset: 540.8999633789062;
    animation: line-anim 2s ease forwards 0.1s;

}
#logo path:nth-child(3){
    stroke-dasharray: 827.9262084960938;
    stroke-dashoffset: 827.9262084960938;
    animation: line-anim 2s ease forwards 0.2s;

}
#logo path:nth-child(4){
    stroke-dasharray: 303.6960144042969;
    stroke-dashoffset: 303.6960144042969;
    animation: line-anim 2s ease forwards 0.3s;

}
#logo path:nth-child(5){
    stroke-dasharray: 537.2930297851562;
    stroke-dashoffset: 537.2930297851562;
    animation: line-anim 2s ease forwards 0.4s;

}
#logo path:nth-child(6){
    stroke-dasharray: 303.6960144042969;
    stroke-dashoffset: 303.6960144042969;
    animation: line-anim 2s ease forwards 0.5s;

}
#logo path:nth-child(7){
    stroke-dasharray: 623.46875;
    stroke-dashoffset: 623.46875;
    animation: line-anim 2s ease forwards 0.6s;

}
#logo path:nth-child(8){
    stroke-dasharray: 1242.220458984375;
    stroke-dashoffset: 1242.220458984375;
    animation: line-anim 2s ease forwards 0.9s;

}
#logo path:nth-child(9){
    stroke-dasharray: 584.1041259765625;
    stroke-dashoffset: 584.1041259765625;
    animation: line-anim 2s ease forwards 1.2s;

}
#logo path:nth-child(10){
    stroke-dasharray: 833.5394897460938;
    stroke-dashoffset: 833.5394897460938;
    animation: line-anim 2s ease forwards 1.4s;

}
#logo path:nth-child(11){
    stroke-dasharray: 537.2935791015625;
    stroke-dashoffset: 537.2935791015625;
    animation: line-anim 2s ease forwards 1.6s;

}
#logo path:nth-child(12){
    stroke-dasharray: 576.6311645507812;
    stroke-dashoffset: 576.6311645507812;
    animation: line-anim 2s ease forwards 1.8s;

}
#logo path:nth-child(13){
    stroke-dasharray: 301.0957946777344;
    stroke-dashoffset: 301.0957946777344;
    animation: line-anim 2s ease forwards 1.9s;

}
*/