.calendar {
	background: linear-gradient(360deg, #000000 2.11%, rgba(0, 0, 0, 0) 19.48%),
		linear-gradient(260.45deg, #000000 23.1%, rgba(0, 0, 0, 0) 54.33%), url("../assets/calendar-2-2-web.jpg"), black;
	position: relative;
	background-size: cover;
	height: 100vh;
	margin-top: 130px;
	overflow: scroll;
	background-repeat: no-repeat;
	.calendar-grid {
		float: left;
		margin-left: 50vw;
		padding-bottom: 60px;
		padding-top: 100px;
	}
	.normal-headline {
		color: white;
		font-size: 72px;
		font-weight: 600;
		padding-bottom: 30px;
	}

	.item-calendar {
		margin-bottom: 30px;
	}
	.first-view {
		color: white;
		display: grid;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(255, 255, 255, .3);
		grid-template-columns: 25% 40% 35%;
		width: 45vw;
		position: relative;
		font-family: 'Open Sans';
		padding-bottom: 25px;
		.event-information{
			margin-right: 10px;
			
		}
		h3 {
			font-size: 24px;
			font-weight: bold;
			font-family: 'Open Sans';
		}

		h4 {
			font-size: 18px;
			font-weight: bold;
			font-family: 'Open Sans';
		}

		h5 {
			font-size: 12px;
			font-weight: normal;
			margin-top: 3px;
			font-family: 'Open Sans';
		}

		.view-more {
			border: 2px solid white;
			font-weight: 400;
			color: white;
			float: right;
			outline: none;
			background: transparent;
			padding: 10px 0px;
			width: 100%;
			text-decoration: none;
			font-weight: bold;
			font-size: 18px;
			transition: .3s ease;
			cursor: pointer;
			font-size: 12px;
			font-family: 'Open Sans';
			font-weight: 600;

			&:hover {
				background: white;
				color: black;
			}
		}
	}
	.item-calendar:last-child .first-view {
		border-bottom: none;
	}
}

/* Modal Content/Box */

.calendar .calendar-grid {
	.modal {
		// display: none;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgb(0, 0, 0);
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 50000000;
	}
	.modal-content {
		display: block;
		background-color: white;
		margin: 8% auto;
		padding: 20px;
		width: 50%;
		position: relative;
		animation-name: animatetop;
		animation-duration: 0.4s;
		text-align: left;
		h1 {
			text-align: left;
			font-size: 52px;
			color: black !important;
			line-height: 1;
		}
		.sub {
			margin-top: 10px;
			opacity: .8;
			font-size: 23px;
			margin-bottom: 40px;
			color: black;
		}
		.information-2 {
			font-size: 21px;
			margin-top: 30px;
			line-height: 1.7;
			margin-bottom: 30px;
			color: black;
		}
		a {
			padding: 10px 60px;
			text-decoration: none;
			color: white;
			border-radius: 8px;
			font-size: 19px;
			background: black;
		}
		.map {
			margin-top: 60px;
			iframe {
				width: 100%;
				height: 30vh;
			}
		}
	}
	@keyframes animatetop {
		from {
			top: -300px;
			opacity: 0;
		}
		to {
			top: 0;
			opacity: 1;
		}
	}
}

/* The Close Button */

.close {
	color: black;
	opacity: .4;
	font-size: 45px;
	font-weight: bold;
	width: 100%;
	transition: .2s;
	display: flex;
	justify-content: flex-end;
	margin-top: -10px;
}

.close:hover,
.close:focus {
	color: black;
	opacity: 1;
	text-decoration: none;
	cursor: pointer;
}

.no-event-grid {
	margin-top: 20px;
	.no-events {
		display: flex;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		font-family: 'Open Sans';
		padding: 30px 35px;
		color: white;
		background-color: rgba(40, 40, 40, 0.7);
	}
}
.old-event .information,
.old-event .view-more-center {
	opacity: .4;
}

.calendar-page {
	margin-top: 25vh;
}
.link-container {
	text-align: center;
	display: block;
	margin-top: 8vh;

	.calendar-btn {
		text-decoration: none;
		font-size: 15px;
		-webkit-transition: 400ms;
		transition: 400ms;
		background: transparent;
		padding: 10px 160px;
		border: 2px solid white;
		color: white;
		font-weight: 400;
		&:hover {
			background: white;
			color: black;
		}
	}
}
