* {
	padding: 0;
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'solano-gothic-pro-mvb', sans-serif;
}

p,
a {
	font-family: "open sans";
}

body{
	background: #F0F0F0;
}
.wrapper{
	width: 100vw;
	overflow-x: hidden;
}

.align-horizontal{
	display: flex;
	justify-content: center;
}

.align-vertical{
	display: flex;
	align-items: center;
}
.fade-in{
	transition: opacity .9s, transform .9s;
}
.fade-in[data-emergence=hidden] {
	opacity: 0;
	transform: translateY(40px);
  }
  .fade-in[data-emergence=visible] {
	opacity: 1;
	transform: translateY(0px);

  }

  .media-content{
	.fade[data-emergence=hidden]{
		opacity: 0;  
	}
	  .fade[data-emergence=visible]{
		animation: fadein .9s forwards;
		&:nth-child(2){
			animation-delay: .3s;
		}
	  }
  }

  @keyframes fadein{
	  from{
		opacity: 0;
		transform: translateY(40px);
	  }
	  to{
		opacity: 1;
		transform: translateY(0px);
		visibility: visible !important;

	  }
  }

  .CookieConsent{
    opacity: 0 ;
    animation: cookiefade 1.2s forwards 6s;
}

@keyframes cookiefade{
    from{
        opacity: 0 ;
    }
    to{
        opacity: 1 ;
    }
}