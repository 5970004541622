.media-bg{
    background: #F0F0F0;
    min-height: 100vh;
    color: black;
    .media-content{
        width: 85vw;
        margin: 0 auto;
        padding-top: 35vh;
        /* INTRO */
        h2{
            font-size: 72px;
        }
        ul{
            margin-top: 20px;
            display: flex;
            li{
                list-style: none;
                a{
                color: white;
                text-decoration: none;
                margin-right: 40px;
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    transform: scaleX(1);
                    height: 1px;
                    bottom: -2px;
                    left: 0;
                    transform-origin: bottom right;
                    transition: .25s;
                    background: rgb(0, 0, 0);
                }
                &:hover::before{
                    width: 0%;
                }
                }
            }
        }

        /* YOUTUBE */
        .youtube-section{
            margin-top: 80px;
            padding-bottom: 70px;
            h3{
                font-size: 34px;
                margin-bottom: 10px;
                &:not(:first-child){
                    margin-top: 60px;
                }
            }
            
        }

        /* SOUNDCLOUD */ 
        .soundcloud-section{
            margin-top: 90px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 60px;
            h3{
                font-size: 72px;
                margin-bottom: 10px;
            }
        }

        /* IMPRESSIONEN */
        .impressionen-section{
            h2{
                margin-bottom: 30px;
                margin-top: 120px
            }
            img{
                width: 60vw;
            }
            p{
                font-family: 'Open Sans';
                margin-top: 7px;
                font-size: 18px;
                opacity: .7;
            }
            .container-arrow{
                width: 60vw;
                float: left;
                .slick-arrow{
                    float: right;
                    background: none;
                z-index: 200;
        
                    border: none;
                    outline: none;
                    cursor: pointer;
                    &:nth-child(1){
                        margin-left: 10px;
                    }
                }
            }
        }

            /* Diskographie & Publikationen */ 
    .media-grid-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 80px;
        margin-top: 20vh;
        h2{
            margin-bottom: 50px;

        }
        .item{
            h3{
                font-family: Libre Baskerville;
                font-weight: bold;
                font-size: 42px;
            }
        }
        .item:nth-child(1){
            .desc{
                font-size: 16px;
                margin-top: 20px;
                width: 80%;
            }
            .disko-grid{
                a{
                    text-decoration: none;
                    color: black;
                    display: block;
                .item-disko{
                    width: 100%;
                    border-bottom: 1px solid rgba(0, 0, 0, .4);
                    padding-bottom: 10px;
                    p{
                        font-size: 21px;
                    font-family: 'Open Sans';

                    }
                    
                }
                &:not(:first-child){
                    margin-top: 15px;
                }
               
            }
            }
            .media-sub-grid{
                width: 90%;
                margin-top: 50px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                .item-sub{
                    img{
                        width: 100%;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
        .item:nth-child(2){
            .desc{
                margin-top: 20px;
    
                }
                .cover-grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 20px;
                }
                .cover-desc{
                    display: block;
                    text-decoration: none;
                    color: black;
                    a{
                        color: black;
                        text-decoration: none;
                    }
                }
                .desc-a{  
                    color: black;
                    text-decoration: none;
                    position: relative;
                    
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        transform: scaleX(1);
                        height: 1px;
                        bottom: -2px;
                        left: 0;
                        transform-origin: bottom right;
                        transition: .25s;
                        background: white;
                    }
                    &:hover::before{
                        width: 0%;
                    }
                }
                img{
                    width: 100%;
                    display: inline-block;
                    filter: drop-shadow(1px 2px 16px rgba(255, 255, 255, 0.05));
                }
            }
    }

    /* SOCIAL MEDIA */ 
    .social-media-content{
        margin-top: 120px;
        padding-bottom: 150px;
        width: 85vw;
        h2{
            margin-bottom: 20px;
        }
        .social-link-section{
            width: 85vw;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            a{
                display: flex;
                align-items: center;
                height: 170px;
            svg, img{
                height: 80px;
                width: 100%;
            }
            &:nth-child(1){
                img{
                    width: 120px;
                }
            }
        }
        }
    }

    }
    
}



.slick-slider .slick-arrow{
    display: none !important;
}
.slider-box{
    outline: none;
}

#soundcloud, #impressionen, #disko, #social{
    height: 90px;
}
