.dark-bg{
	background: #F0F0F0;
}
.animation-bi{
	height: auto;
	width: 100vw;
	margin-top: 90px;
	background: url('../assets/bio-2-web.jpg');
	background-position-x: center;
	background-position-y: top;
	background-size: 100%;
	background-repeat: no-repeat;
	.animation-bio{
	height: auto;
	width: 100vw;
	background-position-y: top;
	transition: all .4s;
	position: relative;
	margin-top: 0;
	padding-bottom: 90px;

	
	}
  
  
  }
  .trigger{
	.textbio{
	  height: auto;
	  width: 85vw;
	  margin: 0 auto;
	  opacity: 0;
	  color: black;
	  .headline {
		padding-top: 100px;
		font-size: 69px;
		line-height: 86px;
		font-family: Libre Baskerville;
		margin-bottom: 3%;
		  .normal-headline{
			margin-top: 0;
		  }
	  }
	  .inside-bio{ 
		  padding-top: 25vh;
		  color: black;
		  .item{
			h2{
				font-size: 72px;
				margin-bottom: 15px;
			}
			p{
			  column-count: 2;
			  line-height: 1.8;
			}
		  }
		  img{
			width: 100%;
		  }
		  .bio-text {
		  column-count: 2;
		  column-gap: 50px;
		  line-height: 2.2;
		  p{
			text-align: justify;
		  }
	  }
	  }
  
  
	}
  }
  
.item-press{
	margin-top: 80px;
	h2{
		font-size: 72px;
		margin-bottom: 15px;
	}
	.press-grid{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		.item-grid-press{
			img{
				margin-bottom: 30px;
				margin-top: 30px;
			}
			a{
				padding: 13px 0px;
				width: 100%;
				display: block;
				text-align: center;
				text-decoration: none;
				transition: .4s ease;
				box-sizing: border-box;
				color: black; 
				border: 2px solid black;
				&:hover{
					background: black;
					color: white;
				}
			}
		}
	}
}